<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <div class="page-wrapper login">
            <div class="page-inner bg-brand-gradient">
                <div class="page-content-wrapper bg-transparent m-0">
                    <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                        <div class="d-flex align-items-center container p-0">
                            <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                                <a href="javascript:void(0)"
                                   class="page-logo-link press-scale-down d-flex align-items-center">
                                    <img src="media/logo.svg" class="logo-edutalk" alt="Edutalk Tư Vấn"
                                         aria-roledescription="logo">
                                    <span class="page-logo-text mr-1"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1" style="background: url(media/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                        <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 ml-auto">
                                    <div class="card p-4 rounded-plus bg-faded">
                                        <h2>Đăng nhập tài khoản</h2>
                                        <h5 class="mb-5 text-black-50">Chào mừng bạn đến Edutalk - Nền tảng tìm kiếm và đặt lớp học. Webiste dành riêng cho <span class="text-danger">GIẢNG VIÊN</span> tại Edutalk!</h5>
                                        <div v-if="error_message" class="fv-plugins-message-container">
                                            <p class="error"><i class="far fa-exclamation-triangle"></i> {{ error_message }} </p>
                                        </div>
                                        <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)">
                                            <div class="form-group">
                                                <ValidationProvider vid="email" name="Email" rules="required"
                                                                    v-slot="{ errors, classes }">
                                                    <input type="email" ref="email" name="email" class="form-control form-control-lg"
                                                           :class="classes"
                                                           placeholder="Nhập email hoặc số điện thoại"
                                                           v-model=form.email>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group">
                                                <ValidationProvider name="Mật khẩu" rules="required|min:6"
                                                                    v-slot="{ errors, classes }">
                                                    <input type="password" ref="password" name="password" class="form-control form-control-lg"
                                                           :class="classes"
                                                           placeholder="Mật khẩu"
                                                           v-model=form.password>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="row no-gutters">
                                                <div class="col-lg-12 pr-lg-1 my-2">
                                                    <button type="submit" id="login-submit" ref="login-submit"
                                                            :disabled="isLoading"
                                                            class="btn btn-primary btn-block btn-lg waves-effect waves-themed">
                                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="isLoading"></span>
                                                      Đăng nhập
                                                    </button>
                                                </div>
                                                <div class="col-lg-12 mt-3 text-center justify-content-edutalk ">Bạn quên mật khẩu?
                                                    <router-link to="/forgot-password" v-slot="{ href, navigate }">
                                                        <a :href="href"  title="Đăng ký tài khoản"
                                                           @click="navigate"
                                                           data-filter-tags="Đăng ký tài khoản"
                                                           class=" waves-effect waves-themed">
                                                            Lấy lại mật khẩu ngay
                                                        </a>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col col-md-6 col-lg-7 hidden-sm-down">
                                    <lottie-animation :path="'media/lottie/english-teacher.json'" />
                                </div>
                            </div>
                            <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                                          target="_blank">edutalk.edu.vn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>
<!-- Load login custom page styles -->

<script>
    import {mapGetters, mapState} from "vuex";
    import {LOGIN} from "@/core/services/store/auth.module";
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
    export default {
        name: "edutalk-login",
        components: {
            LottieAnimation
        },
        data() {
            return {
                publicPath: process.env.VUE_APP_BASE_URL,
                state: "signin",
                // Remove this dummy login info
                form: {
                    email: "",
                    password: ""
                },
                check_error: false,
                error_message: '',
                isLoading: false,
            };
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            ...mapGetters(["currentUser"]),
        },
        mounted() {

        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                var email = this.form.email;
                var password = this.form.password;
                let path = '/home';

                this.$store
                    .dispatch(LOGIN, { email, password })
                    .then(() => {
                      if (path === '/new-password' || path === '/forgot-password' || path === '/otp') path = '/home';
                      this.$router.push({ path: path })
                    }).catch((err) => {
                      if (err?.data?.data?.message_validate_form){
                        this.$refs.form.setErrors(err?.data?.data?.message_validate_form);
                      }else{
                        this.error_message = err?.data?.message;
                      }
                    }).finally(() => this.isLoading = false);
            },

        }
    };
</script>
